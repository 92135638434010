<template>
  <v-alert
    :value="isDisplaying && page.length > 0"
    :class="`mx-${marginX} mb-${marginB}`"
    color="info"
    border="left"
    outlined
    transition="fade-transition"
  >
    <v-btn
      absolute
      @click="toggleHelp()"
      :color="INFO"
      icon
      right
      top
    >
      <v-icon>{{ HELP_ICON }}</v-icon>
    </v-btn>
    <p v-show="!hideTitle" class="overline info--text ml-2 mb-0">how to use this page</p>
    <ul v-if="getText(page).length > 1" :class="`${DEFAULT_TEXT}`">
      <li v-for="(text, i) in getText(page)" :key="i" v-html="text" />
    </ul>
    <div v-else :class="`${DEFAULT_TEXT}`" v-html="getText(page)[0]" />
  </v-alert>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { TEXT_COLOR, COLOR, ICONS } from '../../constants'

export default {
  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    },
    marginB: {
      type: String,
      default: '4',
    },
    marginX: {
      type: String,
      default: '4',
    },
    page: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      isDisplaying: 'help/isDisplaying',
      getText: 'help/getText',
    })
  },
  methods: {
    ...mapActions({
      toggleHelp: 'help/toggleHelp',
    })
  },
  created () {
    this.DEFAULT_TEXT = TEXT_COLOR.DEFAULT
    this.HELP_ICON = ICONS.HELP
    this.INFO = COLOR.INFO
  }
}
</script>
